import React, { useEffect, useState } from 'react';

const HubspotGetStartedForm = ({
  title,
  subtitle,
  formId,
  portalId,
  formSuccessMessageTitle,
  className = '',
}) => {
  const [submitSucess, setSubmitSucess] = useState(false);
  const createForm = () => {
    window.hbspt.forms.create({
      region: 'na1',
      portalId,
      formId: formId,
      target: '#hubspotGetStartedForm',
    });
  };

  const onHubSubmit = (event) => {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted'
    ) {
      setSubmitSucess(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onHubSubmit);
    if (!window.hbspt) {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        if (window.hbspt) {
          createForm();
        }
      });
    } else {
      createForm();
    }
    return () => {
      window.removeEventListener('message', onHubSubmit);
    };
  }, []);

  return (
    <div className={className}>
      {submitSucess ? (
        <p className="mt-4 text-center text-black">{formSuccessMessageTitle}</p>
      ) : (
        <div>
          <h2 className="text-center text-2xl font-bold leading-[25.2px] text-black">
            {title}
          </h2>
          <p className="pt-[10px] text-center text-sm font-medium leading-[19.6px] text-[#706C89]">
            {subtitle}
          </p>
          <div className="pt-6" id="hubspotGetStartedForm" />
        </div>
      )}
    </div>
  );
};

export default HubspotGetStartedForm;
