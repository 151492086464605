import React from 'react';
import Layout from '@/components/common/v5/layout';
import Container from '@/components/common/container';
import { graphql } from 'gatsby';
import HubspotGetStartedForm from '@/components/common/get-started-form';
import SEO from '@/components/seo';
import { PortableText } from '@portabletext/react';
import { cn } from '@/utils/tailwindUtils';

const portableTextMap = {
  marks: {
    strong: ({ children }) => <span className="font-bold">{children}</span>,
    highlight: ({ children }) => (
      <span className="highlight-hero">{children}</span>
    ),
  },
};

const Logos = ({ logos, containerClassName = '', className = '' }) => {
  return (
    <div className={cn('flex flex-wrap justify-center', containerClassName)}>
      {logos.map((logo, i) => (
        <img
          key={logo.asset.url}
          src={logo.asset.url}
          alt="logo"
          className={cn('h-8 w-auto', {
            [className]: i === 1,
          })}
        />
      ))}
    </div>
  );
};

const ContactUsPage = ({ data }) => {
  const { sanityGetStartedV2 } = data;
  const { _rawTitle, form, quote, features } = sanityGetStartedV2;

  return (
    <Layout>
      <div className="relative bg-white">
        <img
          src="/blurs/1.svg"
          alt="pink blur"
          className="pointer-events-none absolute -right-[8.5rem] top-0 z-[0] h-[550px] w-[650px]"
        />
        <Container className="relative pb-[88px] pt-10 lg:pb-[120px] lg:pt-16">
          <div>
            <div className="flex flex-col-reverse gap-10 lg:flex-row lg:justify-between">
              <div className="item-center flex flex-grow flex-col">
                <h2 className="max-w-[664px] text-left text-[34px] font-bold leading-[35.7px] lg:text-[50px] lg:leading-[52.5px]">
                  <PortableText
                    components={portableTextMap}
                    value={_rawTitle}
                  />
                </h2>
                <ul className="flex flex-col gap-3 pt-10">
                  {features.map((i) => (
                    <li className="flex gap-3" key={i.title}>
                      <img
                        src={i.image.asset.url}
                        alt={i.title}
                        className="mt-[2px] h-4 w-4"
                      />
                      <div>
                        <p className="[text-[#383645] text-lg leading-[21.09px]">
                          {i.title}
                        </p>
                        <p className="w-full max-w-[505px] pt-1 text-left text-sm font-medium leading-[19.6px] text-[#706C89]">
                          {i.subtitle}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="mt-6 hidden max-w-[500px] border-t border-solid border-t-[#CBCBCB] lg:block">
                  <p className="mx-auto w-full max-w-[300px] pt-6 text-center text-base font-normal leading-6">
                    <PortableText
                      components={portableTextMap}
                      value={form._rawLogosTitle}
                    />
                  </p>
                  <Logos
                    containerClassName="pt-6 pb-8 gap-x-8 lg:gap-x-16 gap-y-8"
                    logos={form.logos.slice(0, 3)}
                  />
                  <Logos
                    containerClassName="gap-x-8 lg:gap-x-16 gap-y-8"
                    logos={form.logos.slice(3, form.logos.length)}
                  />
                </div>
              </div>

              <div>
                <HubspotGetStartedForm
                  formSuccessMessageTitle={form.formSuccessMessageTitle}
                  title={form.hubspotFormTitle}
                  subtitle={form.hubspotFormSubtitle}
                  formId={form.hubspotFormId}
                  portalId={form.hubspotPortalId}
                />
                <div className="mt-6 border-t border-solid border-t-[#CBCBCB] lg:hidden">
                  <p className="mx-auto w-full max-w-[300px] pt-6 text-center text-base font-normal leading-6">
                    <PortableText
                      components={portableTextMap}
                      value={form._rawLogosTitle}
                    />
                  </p>
                  <Logos
                    containerClassName="pt-6 pb-8 gap-x-8 lg:gap-x-16 gap-y-8"
                    logos={form.logos.slice(0, 3)}
                  />
                  <Logos
                    containerClassName="gap-x-8 lg:gap-x-16 gap-y-8"
                    logos={form.logos.slice(3, form.logos.length)}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center pt-[88px] lg:pt-[85px]">
              <div className="gradient-border px-10 pb-10 pt-6">
                <img
                  className="mx-auto"
                  src={quote.icon.asset.url}
                  alt="quote"
                />
                <p className="w-full max-w-[774px] pt-6 text-center text-xl font-normal italic leading-7">
                  {quote.text}
                </p>
              </div>
              <div className="flex items-center gap-4 pt-4">
                <img
                  className="h-[53px] w-[53px]"
                  src={quote.authorImg.asset.url}
                  alt={quote.authorFullname}
                />
                <p className="flex flex-col">
                  <span className="text-lg font-bold leading-[21.09px] text-[#383645]">
                    {quote.authorFullname}
                  </span>
                  <span className="text-sm font-medium leading-[19.6px] text-[#706C89]">
                    {quote.authorPosition}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query GetStartedQuery {
    sanityGetStartedV2 {
      features {
        title
        subtitle
        image {
          asset {
            url
          }
        }
      }
      form {
        hubspotFormId
        hubspotPortalId
        formSuccessMessageTitle
        hubspotFormTitle
        hubspotFormSubtitle
        _rawLogosTitle
        logos {
          asset {
            url
          }
        }
      }
      _rawTitle
      quote {
        authorFullname
        authorPosition
        text
        authorImg {
          asset {
            url
          }
        }
        icon {
          asset {
            url
          }
        }
      }
    }
    sanityGetStarted {
      title
      subtitle
      seo {
        metaTitle
        metaDescription
        image {
          asset {
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
      hubspotFormId
      formSuccessMessageTitle
      formSuccessMessageCta {
        ... on SanityExternalLink {
          _type
          label
          url
        }
      }
      productFeatures {
        title
        features {
          title
          subtitle
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default ContactUsPage;

export const Head = ({ data }) => {
  const { seo } = data.sanityGetStarted;

  return (
    <SEO
      title={seo.metaTitle}
      description={seo.metaDescription}
      image={seo && seo.image ? seo.image.asset.localFile.publicURL : null}
      imageWidth={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.width
          : null
      }
      imageHeight={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.height
          : null
      }
    />
  );
};
